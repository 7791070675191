import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { minWidth } from '@emico/styles'
import { Container, H1 } from '@emico/ui'

import { CraftPageBlocksFragmentCraftHeroEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import ButtonPrimary, { getThemeColor } from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import CraftBlock from './CraftBlock'

enum HeroButtonTypeEnum {
  SECONDARY = 0,
  PRIMARY = 1,
}

enum HeroVariantEnum {
  HIGH = 'high',
  LOW = 'low',
}

const HeroWrapper = styled(CraftBlock, {
  shouldForwardProp: (prop) => !['variant'].includes(prop.toString()),
})<{
  variant: string | undefined
}>`
  width: 100%;
  height: ${({ variant }) =>
    variant === HeroVariantEnum.HIGH
      ? `calc(
        80vh - (${theme.sizes.headerHeight} + ${theme.sizes.uspBarHeight})
      )`
      : '400px'};
  position: relative;
  padding: ${theme.spacing.md} 0;

  @media ${minWidth('lg')} {
    height: ${({ variant }) =>
      variant === HeroVariantEnum.HIGH
        ? `calc(
        100vh -
          (
            ${theme.sizes.headerHeight} + ${theme.sizes.headerCollapsibleHeight} +
              ${theme.sizes.uspBarHeight}
          )
      )`
        : '550px'};
    padding: ${theme.spacing.lg} 0;
  }

  &::after {
    content: '';
    display: block;
    background-color: ${theme.colors.black};
    opacity: 0.3;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;

  @media ${minWidth('lg')} {
    align-items: center;
  }
`

const StyledPicture = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

const Content = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${minWidth('lg')} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
`

const Header = styled.header`
  margin-bottom: ${theme.spacing.md};

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.xl};
  }
`

const Title = styled(H1)`
  color: ${theme.colors.textLight};
  text-transform: none;
`

const TitlePart = styled('span', {
  shouldForwardProp: (prop) =>
    !['fontSize', 'fontWeight', 'isUppercase'].includes(prop.toString()),
})<{
  fontSize?: string
  fontWeight?: string
  isUppercase?: boolean
}>`
  display: inline-block;
  margin: 0 ${theme.spacing.sm} ${theme.spacing.sm} 0;
  font-size: ${({ fontSize }) =>
    fontSize === 'normal' ? theme.fontSizes.lg : theme.fontSizes['4xl']};
  font-weight: ${({ fontWeight }) =>
    fontWeight === 'normal'
      ? theme.fontWeights.normal
      : fontWeight === 'medium'
      ? theme.fontWeights.medium
      : theme.fontWeights.black};
  text-transform: ${({ isUppercase }) => isUppercase && 'uppercase'};

  &:last-child {
    margin-bottom: 0;
  }
`

const Links = styled.div`
  display: grid;
  grid-gap: ${theme.spacing.sm};

  @media ${minWidth('lg')} {
    grid-auto-columns: minmax(300px, auto);
  }
`

interface Props {
  block: CraftPageBlocksFragmentCraftHeroEntry
  themeColor?: string
}

const CraftHeroBlock = ({ block, themeColor }: Props) => {
  const background = block.background?.[0]

  return (
    <HeroWrapper variant={block.variant ?? undefined}>
      <StyledPicture
        style={{}}
        breakpoints={{
          mobile: {
            url: background?.mobileUrl ?? '',
            width: 700,
            height: 1115,
          },
          tablet: {
            url: background?.tabletUrl ?? '',
            width: 834,
            height: 834,
          },
          desktop: {
            url: background?.url ?? '',
            width: (background?.width ?? 0) / 2,
            height: (background?.height ?? 0) / 2,
          },
        }}
        alt={background?.title ?? ''}
        lazy={false}
      />

      <StyledContainer>
        <Content>
          {block.titleWithPartialStyling?.length !== 0 && (
            <Header>
              <Title>
                {block.titleWithPartialStyling?.map((titlePart, index) => {
                  if (!titlePart?.text) {
                    return null
                  }

                  return (
                    <Fragment key={index}>
                      {titlePart.startOnNewLine && <br />}

                      <TitlePart
                        fontSize={titlePart.fontSize ?? undefined}
                        fontWeight={titlePart.fontWeight ?? undefined}
                        isUppercase={titlePart.uppercase ?? undefined}
                      >
                        {titlePart.text}
                      </TitlePart>
                    </Fragment>
                  )
                })}
              </Title>
            </Header>
          )}

          {block.heroLinks?.length > 0 && (
            <Links>
              {block.heroLinks.map((link, index) => {
                if (!link?.linkItem?.url || !link.linkItem.customText) {
                  return null
                }

                return link.buttonType ===
                  Boolean(HeroButtonTypeEnum.PRIMARY) ? (
                  <ButtonPrimary
                    key={index}
                    analyticsContext="page.hero"
                    analyticsName={`primary-${link.linkItem?.url}`}
                    craftLink={link.linkItem}
                    colorType={getThemeColor(themeColor)}
                  >
                    {link.linkItem.customText}
                  </ButtonPrimary>
                ) : (
                  <ButtonSecondary
                    key={index}
                    analyticsContext="page.hero"
                    analyticsName={`secondary-${link.linkItem?.url}`}
                    craftLink={link.linkItem}
                  >
                    {link.linkItem.customText}
                  </ButtonSecondary>
                )
              })}
            </Links>
          )}
        </Content>
      </StyledContainer>
    </HeroWrapper>
  )
}

export default CraftHeroBlock
