import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftPageBlocksFragment } from '../lib/craftFragments.generated'
import CraftAccordionInformationBlock from './CraftAccordionInformationBlock'
import CraftCompareSpecificationsBlock from './CraftCompareSpecificationsBlock'
import CraftFeaturedCategoriesBlock from './CraftFeaturedCategoriesBlock'
import CraftHeroBlock from './CraftHeroBlock'
import CraftHighlightBannerLinkBlock from './CraftHighlightBannerLinkBlock'
import CraftHtmlBlock from './CraftHtmlBlock'
import CraftImageBlock from './CraftImageBlock'
import CraftImageContentBannerBlock from './CraftImageContentBannerBlock'
import CraftImageWithProductOverviewBlock from './CraftImageWithProductOverviewBlock'
import CraftNewsBlock from './CraftNewsBlock'
import CraftProductRecommendationsBlock from './CraftProductRecommendationsBlock'
import CraftSimpleContentBlock from './CraftSimpleContentBlock'
import CraftTextColumnsBlock from './CraftTextColumnsBlock'
import CraftWizardSliderBlock from './CraftWizardSliderBlock'
import CraftYoutubeVideoBlock from './CraftYoutubeVideoBlock'

const renderBlock = (
  block: CraftPageBlocksFragment,
  index: number,
  themeColor?: string,
) => {
  switch (block.__typename) {
    case 'CraftHeroEntry':
      return (
        <CraftHeroBlock key={index} block={block} themeColor={themeColor} />
      )
    case 'CraftNewsEntry':
      return <CraftNewsBlock key={index} block={block} />
    case 'CraftFeaturedCategoriesEntry':
      return <CraftFeaturedCategoriesBlock key={index} block={block} />
    case 'CraftHighlightBannerLink2Entry':
      return <CraftHighlightBannerLinkBlock key={index} block={block} />
    case 'CraftImageContentBanner2Entry':
      return <CraftImageContentBannerBlock key={index} block={block} />
    case 'CraftSimpleContentEntry':
      return <CraftSimpleContentBlock key={index} block={block} />
    case 'CraftProductRecommendationsEntry':
      return <CraftProductRecommendationsBlock key={index} block={block} />
    case 'CraftImageWithProductOverviewEntry':
      return <CraftImageWithProductOverviewBlock key={index} block={block} />
    case 'CraftImageEntry':
      return <CraftImageBlock key={index} block={block} />
    case 'CraftCompareSpecificationsEntry':
      return <CraftCompareSpecificationsBlock key={index} block={block} />
    case 'CraftWizardSliderEntry':
      return <CraftWizardSliderBlock key={index} block={block} />
    case 'CraftAccordionInformationEntry':
      return <CraftAccordionInformationBlock key={index} block={block} />
    case 'CraftYoutubeVideoEntry':
      return <CraftYoutubeVideoBlock key={index} block={block} />
    case 'CraftTextColumnsEntry':
      return <CraftTextColumnsBlock key={index} block={block} />
    case 'CraftHtmlEntry':
      return <CraftHtmlBlock key={index} block={block} />
    default:
      console.warn(`Craft block type "${block.__typename}" is not implemented`)

      return process.env.NODE_ENV === 'development' ? (
        <div key={index}>
          Craft block type "{block.__typename}" is not implemented
        </div>
      ) : null
  }
}

interface Props {
  blocks: Array<Maybe<CraftPageBlocksFragment>>
  themeColor?: string
}

const CraftPageBlocks = ({ blocks, themeColor }: Props) => {
  if (!blocks || blocks.length === 0) {
    return null
  }

  return (
    <>
      {blocks.map((block, index) => {
        if (!block) {
          return null
        }

        return renderBlock(block, index, themeColor ?? undefined)
      })}
    </>
  )
}

export default CraftPageBlocks
